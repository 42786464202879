import axios from "axios";
import { refreshToken } from "@/utils/api/index";
import router from "@/router";
import { MessageBox, Notification } from "element-ui";
import { sha256 } from "js-sha256";
const CancelToken = axios.CancelToken;
var cancelHash = {};

let Base64 = require("js-base64").Base64;

//  const testURL = ' http://192.168.124.149:8088/api' //服务器域名
// const testURL = "http://localhost:8606/api";


const testURL = "https://tinghai.oceanstellar.com/api";

function getDefaultSystemParams() {
  let time = new Date().getTime();
  return {
    version: 100,
    nid: Base64.encode(sha256("b2h4X29jZWFuc3RlbGxhcg==" + time + "ohx")),
    eid: time,
    languageId: 0,
    sourceType: 1,
    timezone: 8,
  };
}

const httpInstance = axios.create({
  //baseURL:baseURL,
  baseURL: testURL,
  timeout: 500000,
});
httpInstance.setToken = (token) => {
  instance.defaults.headers.accessToken = token;
};

function getRequestKey(config) {
  let { method, url, params, data } = config;
  return [method, url].join("&");
}

// 添加请求信息
let pendingRequest = new Map();
function addPendingRequest(config) {
  let requestKey = getRequestKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
      }
    });
}

// 取消重复请求，移除重复请求信息
function removePendingRequest(config) {
  let requestKey = getRequestKey(config);
  if (pendingRequest.has(requestKey)) {
    // 如果是重复的请求，则执行对应的cancel函数
    let cancel = pendingRequest.get(requestKey);
    cancel(requestKey);
    // 将前一次重复的请求移除
    pendingRequest.delete(requestKey);
  }
}

httpInstance.interceptors.request.use(
  (config) => {
    console.log("请求config:\n" + JSON.stringify(config));
    if (
      config.url == "hsyq/service/getAisShipByBound" || config.url == "/hsyq/service/getAisShipByBoundPoint" ||
      config.url == "hsyq/service/getAisDataByBoundSmall"
    ) {
      // 检查是否存在重复请求，若存在则取消已发的请求
      removePendingRequest(config);
      // 把当前请求信息添加到pendingRequest对象中
      addPendingRequest(config);
    } else {
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 是否正在刷新Token的标记，如果正在刷新token，
// 那么其他的网络请求也会失败，那些失败的请求就先挂起，等待token刷新之后再次发送
// 问题就是，正在刷新token也会发包，只不过时不处理回包
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式，
let http_requests = [];

httpInstance.interceptors.response.use(
  (response) => {
    // console.log("httpInstance get response:\n", response);

    if (
      response.config.url == "/hsyq/service/getAisShipByBound" || response.config.url == "/hsyq/service/getAisShipByBoundPoint" ||
      response.config.url == "/hsyq/service/getAisDataByBoundSmall"
    ) {
      // 从pendingRequest对象中移除请求
      removePendingRequest(response.config);
    } else {
    }
    console.error(`${response.config.url}继续运行`);
    // 异常处理
    if (response.data.resultCode != 0) {
      if (response.data == undefined || response.data == null || response.data == "") {
        Notification.error({
          title: "Error",
          message: "请求失败",
        });
        return;
      }
      console.log(response.data.errorDesc);

      switch (response.data.errorCode) {
        case 100001:
          Notification.error({
            title: "Error",
            message: "系统错误,请重新登陆",
            duration: 1200,
          });
          setTimeout(() => {
            localStorage.clear();
            router.push("/");
          }, 1000);
          break;

        case 100002 || 100006 || 100007 || 100010:
          Notification.error({
            title: "Error",
            message: "Token无效,请重新登陆",
            duration: 1200,
          });
          setTimeout(() => {
            localStorage.clear();
            router.push("/");
          }, 1000);
          break;

        case 100003:
          // Token 过期，需要把其他异步请求挂起，先进行token刷新工作，当前失败的请求config
          const config = response.config;
          if (!isRefreshing) {
            isRefreshing = true;
            return refreshToken()
              .then((res) => {
                let resultData = res.data.resultData;
                localStorage.setItem("accessToken", resultData.accessToken);
                localStorage.setItem("refreshToken", resultData.refreshToken);
                localStorage.setItem("expireTime", resultData.expireTime);
                config.headers.accessToken = resultData.accessToken;
                console.log("token刷新成功");
                http_requests.forEach((item) => item(resultData.accessToken));
                http_requests = [];
                return httpInstance(config);
              })
              .catch((err) => {
                console.log("refreshToken失败，服务器或回包处理问题");
                // MessageBox({
                //   type:'warning',
                //   message: 'Token刷新失败，请重新登陆',
                //   duration:1200
                // })
                localStorage.clear();
                router.push("/");
              })
              .finally(() => {
                isRefreshing = false;
              });
          } else {
            return new Promise((resolve) => {
              http_requests.push((token) => {
                config.headers.accessToken = token;
                resolve(httpInstance(config));
              });
            });
          }

        case 100004:
          Notification.error({
            title: "Error",
            message: "验证系统参数错误,请退出登陆重试或联系管理员",
            duration: 1600,
          });
          break;
        case 100005:
          Notification.error({
            title: "Error",
            message: "AppKey过期",
            duration: 1200,
          });
          setTimeout(() => {
            localStorage.clear();
            router.push("/");
          }, 1000);
          break;

        case 100008:
          //token失效或为空，重新登陆
          Notification.warning({
            title: "Warning",
            message: "登陆信息失效，请重新登陆",
            duration: 1200,
          });
          setTimeout(() => {
            localStorage.clear();
            router.push("/");
          }, 1000);
          break;

        case 100009:
          this.$message({
            message: "远程主机已断开，请重试或重新登陆",
            type: "error",
            duration: 1400,
          });
          break;
        
        case 100010:
            break;

        case 100011:
          this.$message({
            message: "URL路径错误，资源未找到",
            type: "error",
            duration: 1400,
          });
          break;

        case 100012:
          Notification.error({
            title: "Error",
            message: "neo4j数据库错误，请联系管理员",
            duration: 3000,
          });
          break;
        case 200002:
          break;
        case 200003:
          break;
        case 200004:
          break;
        case 200010:
          Notification.error({
            title: "Error",
            message: "验证码错误",
            duration: 1200,
          });
          break;
        case 200011:
          // Notification.error({
          //   title: 'Error',
          //   message: '邮箱已被注册'
          // })
          break;
        case 200012:
          // console.log("邮箱已被注册（邮箱存在）")
          break;
        case 200013:
          Notification.error({
            title: "Error",
            message: "注册失败",
            duration: 1200,
          });
          break;
        case 200015:
          Notification.warning({
            title: "Warning",
            message: "邮箱格式错误",
          });
          break;
        default:
            break;
        //   Notification.error({
        //     title: "Error",
        //     message: "网络或服务器异常，请重新登陆",
        //   });
        //   setTimeout(() => {
        //     localStorage.clear();
        //     router.push("/");
        //   }, 1000);
          return response;
      }
      return response;
    } else {
      // 请求正常时
      return response;
    }
  },
  (error) => {
    removePendingRequest(error.config || {});
    if (axios.isCancel(error)) {
      console.error("已取消的重复请求：" + error.message);
    } else {
      // 添加异常处理
    }
    return Promise.reject(error);
  }
);

// POST请求
export function httpInstance_request(
  url,
  headers_params,
  params,
  system_params = getDefaultSystemParams()
) {
  //console.log(headers_params)
  return httpInstance({
    url: url,
    method: "post",
    headers: {
      ...headers_params,
    },
    params: {
      ...system_params,
    },
    data: {
      ...system_params,
      param: Base64.encode(JSON.stringify(params)),
    },
  });
}

// GET请求，和POST请求区别主要在参数位置和字符串序列化方法上，GET要用qs将JSON序列化成 key1=value1&key2=value2&... 的形式
export function httpInstance_request_get(
  url,
  headers_params,
  params,
  system_params = getDefaultSystemParams()
) {
  return httpInstance({
    url: url,
    method: "get",
    headers: {
      ...headers_params,
    },
    params: {
      ...system_params,
      param: Base64.encode(JSON.stringify(params)),
    },
  });
}
